import * as React from 'react';
import { graphql } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';
import Layout from '../components/layout';
import './about.scss';
import { AboutPageQuery } from '../../graphql-types';
import { IconWrapper, FoodIcons } from '../components/iconWrapper';

interface IProps {
  data: AboutPageQuery;
}
const About: React.FC<IProps> = ({ data }) => {
  const about = data.prismic.allAbouts.edges[0].node;

  return (
    <Layout
      className="about"
      pageMeta={{
        title: 'About | Evangeline Harbury Food Stylist',
        description: about.description,
        keywords: 'About, Chef, Food Stylist, Home Economist',
        image: {
          src: about.profile_picture.url,
          height: about.profile_picture.dimensions.height,
          width: about.profile_picture.dimensions.width,
        },
      }}
    >
      <div className="about-container">
        <div className="hero">
          <div className="content">
            <h1>{about.title[0].text}</h1>
            {about.description.map((des) => (
              <p>{des.text}</p>
            ))}
          </div>
          <Img
            fadeIn={true}
            backgroundColor="#F6F6F6"
            className="profile-img"
            style={{ borderColor: about.image_border_colour }}
            fluid={about.profile_pictureSharp.childImageSharp.fluid}
          />
        </div>

        <h2>{about.worked_with_header[0].text}</h2>
        <div className="worked-with">
          {about.worked_with.map((ww, i) => (
            <div>
              <IconWrapper random="icon1">
                <h3>{ww.role}</h3>
              </IconWrapper>
              <ul>
                {ww.list.map((l) => (
                  <li>{l.text}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query AboutPage {
    prismic {
      allAbouts {
        edges {
          node {
            title
            description
            profile_picture
            profile_pictureSharp {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            image_border_colour
            worked_with_header
            worked_with {
              role
              list
            }
          }
        }
      }
    }
  }
`;

export default About;
